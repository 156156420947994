<template>
    <div>
        {{ row.item[column] | formatDate('DD.MM.YYYY HH:mm') }}
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'column': [String],
        'refreshTable': Function
    },
}
</script>